@import  '../../global.scss';

.homeContainer {

    .homeHeader{
        margin: auto;
        max-width: 1300px;
        
        .homeHeaderContainer{
            display: flex;
            margin: 150px 120px 150px 120px;
            
            .homeHeaderText{
                flex: 0.7;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .homeHeaderH1{
                    color: $headingPrimary;
                    font-style: italic;
                    font-size: 3.5rem;
                };

                .homeHeaderH2{
                    color: $headingSecondary;
                    font-style: italic;
                    font-size:  2.5rem;
                };
            };
            
            .homeHeaderImgContainer{
                flex: 0.3;
                display: flex;
                justify-content: center;
                align-items: center;
                
                .homeHeaderImg{
                    height: 200px;
                    width: 200px;
                    border-radius: 50%;
                };
            };
        };
    };

    .aboutMe{
        background-color: $backgroundSecondary;
        padding: 120px;
        
        .aboutMeContainer{
            max-width: 1200px;
            margin: auto;

            .aboutMeTitle{
                color: $headingSecondary;
                font-style: italic;
                font-size: 2.2rem;
                padding-bottom: 40px
            };

            .aboutMeContent{
                color: $textPrimary;
                font-size: 1.5rem;

                .aboutContent{
                    display: flex;
                    align-items: center;

                    .aboutMeIcon{
                        color: $textPrimary;
                        text-decoration: none;
                        margin-right: 50px;
                        font-size: 50px;
                    };

                    .aboutMeText{
                        margin: 30px 0px;
                        
                    };
                };
            };
        };
    };

    .emptyContainer{
        height:600px;
        background-color:white;
    }
};