@import '../../global.scss';

.ListIconBlock{
    background-color: $backgroundSecondary;
    padding: 120px;
    
    .ListIconBlockContainer{
        max-width: 1200px;
        margin: auto;

        .ListIconBlockTitle{
            color: $headingSecondary;
            font-style: italic;
            font-size: 2.2rem;
            padding-bottom: 40px
        };

        .ListIconBlockContent{
            color: $textPrimary;
            font-size: 1.5rem;

            .blockContent{
                display: flex;
                align-items: center;

                .blockIcon{
                    color: $textPrimary;
                    text-decoration: none;
                    margin-right: 50px;
                    font-size: 50px;
                };

                .blockText{
                    margin: 30px 0px;
                    
                };
            };
        };
    };
};

// .aboutMe{
//     background-color: $slate;
//     padding: 120px;
    
//     .aboutMeContainer{
//         max-width: 1200px;
//         margin: auto;

//         .aboutMeTitle{
//             color: $orange;
//             font-style: italic;
//             font-size: 2.2rem;
//             padding-bottom: 40px
//         };

//         .aboutMeContent{
//             color: white;
//             font-size: 1.5rem;

//             .aboutContent{
//                 display: flex;
//                 align-items: center;

//                 .aboutMeIcon{
//                     color: green;
//                     text-decoration: none;
//                     margin-right: 50px;
//                     font-size: 50px;
//                 };

//                 .aboutMeText{
//                     margin: 30px 0px;
                    
//                 };
//             };
//         };
//     };
// };