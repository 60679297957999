@import "../../global.scss";

.navbar {
  display: flex;
}

.menu-bars {
  position: fixed;
  color: $iconPrimary;
  margin-left: 2rem;
  margin-top: 2rem;
  font-size: 2.2rem;
  background: none;
}

.menu-bars.active {
  position: fixed;
  display: none;
  margin-left: 2em;
  margin-top: 2em;
  font-size: 3rem;
  background: none;
  background: none;
}

.nav-menu {
  background-color: $backgroundSecondary;
  width: 280px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px 0px 0px;
  list-style: none;
  height: 80px;
}

.nav-text a {
  text-decoration: none;
  color: $textPrimary;
  font-size: 25px;
  font-style: italic;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 36px;
}

.nav-text a:hover {
  background-color: $navHover;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

span {
  margin-left: 16px;
}
