@import "./global.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.app::selection {
  background-color: #8e44ad;
  color: white;
}

// @media screen and (min-width: 400px) {
//     body {
//       background-color: rgb(255, 67, 67);
//     }
//   }

// @media screen and (min-width: 700px) {
// body {
//     background-color: rgb(255, 127, 67);
// }
// }

// @media screen and (min-width: 1000px) {
//     body {
//         background-color: rgb(255, 242, 67);
//     }
//     }

.home,
.projects,
.contact {
  background-color: white;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
