//variables
$headingPrimary: #2d373d;
$headingSecondary: #D65A00;
$backgroundPrimary: #FFFFFF;
$backgroundSecondary: #2d373d;
$backgroundTertiary: #D65A00;
$navHover: #aa4802;
$textPrimary: #FFFFFF;
$textSecondary: #2d373d;
$iconPrimary: #D65A00;
// $slate: #2d373d;
// $orange: #D65A00;
// // $orange: yellow;
// $lilac: #AAA1C8;
// $lavender: #D5C6E0;
// $cream: #F5E6E8;

//map - created to store font sizes and font weights

$font-weights: (
  'thin': 100,
  'extraLight': 200,
  'light': 300,
  'regular': 400,
  'medium': 500,
  'bold': 700,
  'extra-bold': 800,
);

$font-sizes: (
  'extraSmall': 0.75rem,
  'small': 0.875rem,
  'regular': 1rem,
  'medium': 1.25rem,
  'large': 1.5rem,
  'extraLarge': 2rem,
);

// function to usefor fontWeight
@function fontWeight($weight-name) {
  @return map-get($font-weights, $weight-name);
}

// function to usefor fontSize
@function fontSize($size-name) {
  @return map-get($font-sizes, $size-name);
}
