@import '../../global.scss';

.footerContainer{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    bottom: 0;

    .footerTop{
        flex: 0.8;
        background-color: $backgroundSecondary;

        .socialIcons{

            .socialIcon{
                display: flex;
                font-size: 50px;
                width: 50px;
            }
         }

    };

    .footerBottom{
        flex: 0.2;
        background-color: $backgroundTertiary;
        justify-content: center;
        text-align: center;
        color: $textPrimary;
    };
};
